<template>
  <div class="event-details-container">
    <h1 class="mb-5">Dernières mises à jour</h1>
    <div v-if="message" :class="messageClass">{{ message }}</div>

    <div v-if="tasks.length === 0">Il n'y a actuellement aucune tâche.</div>
    <div v-else class="task-list">
      <div
        class="task-item"
        v-for="task in tasks"
        :key="task.taskName"
        :class="{ 'disabled-row': isUpdating && updatingTask !== task.taskName }"
      >
        <!-- First column -->
        <div class="column">{{ task.taskName }}</div>
        <!-- Second column -->
        <div class="column">{{ task.lastExecuted }}</div>
        <!-- Last column (actions) -->
        <div class="actions">
          <button
            @click="triggerUpdate(task.taskName)"
            class="btn btn-secondary btn-sm"
            :disabled="isUpdating"
          >
            <i
              class="fa fa-refresh"
              :class="{ 'fa-spin': updatingTask === task.taskName }"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axiosConfig.js";

export default {
  name: "cronBoard",
  data() {
    return {
      tasks: [],
      message: "",
      isError: false,
      isUpdating: false,
      updatingTask: null, // Track the task being updated
      lastKnownUpdate: {},
    };
  },
  computed: {
    messageClass() {
      return this.isError ? "error" : "success";
    },
  },
  mounted() {
    this.fetchTasks();
  },
  methods: {
   async fetchTasks() {
      // TODO not hardcoded service names
      const taskNames = ["openDataParis", "recommenderUpdate", "cleanUpExpiredTokens"];
      try {
        // Use Promise.allSettled to handle each request individually
        const results = await Promise.allSettled(
          taskNames.map((taskName) =>
            axios
              .get(`cron/last-update/${taskName}`)
              .then((response) => {
                const lastExecuted = new Date(response.data.lastExecuted).toLocaleString();
                return { taskName, lastExecuted };
              })
          )
        );

        // Filter out the ones that failed
        this.tasks = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);

        // Optionally log services that were not found or failed
        results
          .filter((result) => result.status === "rejected")
          .forEach((result, index) => {
            console.warn(`Service ${taskNames[index]} not found. Skipping.`);
          });

        // Update lastKnownUpdate for successful tasks
        this.tasks.forEach(
          (task) => (this.lastKnownUpdate[task.taskName] = task.lastExecuted)
        );
      } catch (error) {
        console.error("Error fetching tasks:", error);
        this.message = "Échec du chargement des tâches.";
        this.isError = true;
      }
    },
    async triggerUpdate(taskName) {
      this.isUpdating = true;
      this.updatingTask = taskName;
      this.message = `Mise à jour de ${taskName} en cours. Veuillez patienter...`;
      this.isError = false;

      try {
        await axios.post(`cron/trigger-update/${taskName}`);
        this.message = `Mise à jour demandée pour ${taskName}. Vérification en cours...`;
        this.pollForUpdate(taskName);
      } catch (error) {
        console.error(`Error triggering update for ${taskName}:`, error);
        this.message = `Échec du déclenchement de la mise à jour pour ${taskName}.`;
        this.isError = true;
        this.isUpdating = false;
        this.updatingTask = null;
      }
    },
    pollForUpdate(taskName) {
      const POLL_INTERVAL = 3000;
      const MAX_ATTEMPTS = 10;
      let attempts = 0;

      const checkForUpdate = async () => {
        try {
          const response = await axios.get(`cron/last-update/${taskName}`);
          const newTimestamp = new Date(response.data.lastExecuted).toLocaleString();

          if (newTimestamp !== this.lastKnownUpdate[taskName]) {
            this.message = `✅ Mise à jour terminée pour ${taskName}.`;
            const updatedTask = this.tasks.find(task => task.taskName === taskName);
            if (updatedTask) updatedTask.lastExecuted = newTimestamp;
            this.lastKnownUpdate[taskName] = newTimestamp;
            this.isUpdating = false;
            this.updatingTask = null;
          } else if (attempts < MAX_ATTEMPTS) {
            attempts++;
            setTimeout(checkForUpdate, POLL_INTERVAL);
          } else {
            this.message = `La mise à jour de ${taskName} prend plus de temps que prévu. Veuillez recharger la page.`;
            this.isUpdating = false;
            this.updatingTask = null;
          }
        } catch (error) {
          console.error(`Error checking update status for ${taskName}:`, error);
          this.message = `Erreur lors de la vérification de la mise à jour pour ${taskName}.`;
          this.isError = true;
          this.isUpdating = false;
          this.updatingTask = null;
        }
      };

      checkForUpdate();
    },
  },
};
</script>

<style scoped>
.event-details-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.task-list {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Flex container for task items */
.task-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Grey out rows that are not being updated */
.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

/* First two columns: centered and take equal remaining space */
.task-item .column {
  flex: 1;
  text-align: center;
}

/* Last column (actions): right-aligned with auto width */
.task-item .actions {
  flex: none;
  text-align: right;
}

/* Hover effect */
.task-item:hover {
  background-color: #f1f1f1;
}

/* Style for button container if needed */
.actions {
  display: flex;
  gap: 10px;
}

.btn-secondary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.fa-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
